import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import HolidayMode from '@/components/utility/buttons/HolidayMode';
import useAccount from '@/hooks/useAccount';
import AuthAPI from '@/network/AuthAPI';
import PitchesAPI from '@/network/PitchesAPI';
import UserAPI from '@/network/UserAPI';

import PaypalLogin from '../components/utility/buttons/PaypalLogin';
import Loading from '../components/utility/Loading';
import UserHeader from '../components/utility/navigation/UserHeader';
import AppContext, { SnackbarContextModel } from '../contexts/AppContext';

export type FormState = {
  firstname: string;
  lastname: string;
  email: string;
  bio: string;
  vibe: string;
  businessName: string;
};

export default function SettingsPage() {
  const { unhurdUser, refetchUser } = useAccount();
  document.title = 'Settings';
  const location = useLocation();
  const [value, setValue] = useState(location.state || 0);
  const [open, setOpen] = useState(false);
  const [showSeed, setShowSeed] = useState(false);
  const [isCreatingPitches, setIsCreatingPitches] = useState<boolean>(false);
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState<boolean>(false);

  const { logout } = useAuth0();

  const [isLoading, setIsLoading] = useState(true);
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const [formState, setFormState] = useState<FormState>({
    firstname: unhurdUser?.firstName || '',
    lastname: unhurdUser?.lastName || '',
    email: unhurdUser?.email || '',
    bio: unhurdUser?.bio || '',
    vibe: unhurdUser?.vibe || '',
    businessName: unhurdUser?.businessName || '',
  });

  useEffect(() => {
    if (unhurdUser) {
      setFormState({
        firstname: unhurdUser.firstName,
        lastname: unhurdUser.lastName,
        email: unhurdUser.email,
        bio: unhurdUser.bio,
        vibe: unhurdUser.vibe,
        businessName: unhurdUser.businessName,
      });
      setIsLoading(false);
    }
    if (import.meta.env.VITE_NODE_ENV === 'dev') {
      setShowSeed(true);
    }
  }, [unhurdUser]);

  const handleChange = (_event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const handleFormChanges = useCallback(async () => {
    if (!unhurdUser || !unhurdUser.userId || !unhurdUser.id) return;
    setIsSavingChanges(true);
    if (formState.bio.length < 100) {
      setIsSavingChanges(false);
      return dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Bio must be at least 100 characters long',
          type: 'error',
        },
      });
    }
    try {
      await UserAPI.updateUser({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser.id, data: formState });
      await refetchUser();
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: "You've successfully updated your details",
          type: 'success',
        },
      });
      setIsSavingChanges(false);
    } catch {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Oops, something went wrong. Try updating your details again`,
          type: 'error',
        },
      });
      setIsSavingChanges(false);
    }
  }, [formState, unhurdUser, refetchUser, dispatchSnackbar]);

  const deleteAccount = () => {
    setOpen(true);
  };

  const handlePasswordChanges = async () => {
    if (!unhurdUser || !unhurdUser?.email) return;
    setIsChangingPassword(true);
    const data = {
      client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
      email: (formState.email = unhurdUser?.email),
      connection: 'Username-Password-Authentication',
    };
    try {
      await UserAPI.sendPasswordChangeEmail(data);
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Email sent, this may take a couple of minutes to come through.`,
          type: '',
        },
      });
    } catch {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Oops, something went wrong. Try changing your password again`,
          type: 'error',
        },
      });
    } finally {
      setIsChangingPassword(false);
    }
  };

  const formUpdate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleClose = async (e: boolean) => {
    if (e === true) {
      if (!unhurdUser || !unhurdUser.userId || !unhurdUser.id) return;
      setIsDeletingAccount(true);
      try {
        await UserAPI.deleteUser({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id });
        AuthAPI.removeAllTokens();
        logout({ logoutParams: { returnTo: window.location.origin } });
      } catch {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Oops, something went wrong. Try deleting your account again`,
            type: 'error',
          },
        });
      } finally {
        setIsDeletingAccount(false);
      }
    }

    setOpen(false);
  };

  const createDemoPitches = async () => {
    if (!unhurdUser?.spotifyAccounts || !unhurdUser?.spotifyAccounts[0].id) return;
    setIsCreatingPitches(true);
    try {
      await PitchesAPI.seedPitches(unhurdUser?.spotifyAccounts[0].id);
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `New demo pitches have been added to your verified playlists`,
          type: 'success',
        },
      });
    } catch {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: `Oops, something went wrong. Try generating pitches again`,
          type: 'error',
        },
      });
    } finally {
      setIsCreatingPitches(false);
    }
  };

  return (
    <>
      {!isDeletingAccount ? (
        <div className="page-content">
          <UserHeader />
          <div>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Your details" data-testid="your-details" />
                  <Tab label="Payment details" data-testid="payment-details" />
                  <Tab label="Security settings" data-testid="security-settings" />
                  {showSeed && <Tab label="Development Settings" />}
                </Tabs>
              </Box>
            </Box>
            {/* User form tab */}
            <div hidden={value !== 0}>
              <div className="user-info-form">
                {!isLoading && (
                  <form>
                    <div className="user-form-names">
                      <label>
                        <p>First name</p>
                        <input
                          placeholder="John"
                          name="firstname"
                          value={formState.firstname}
                          onChange={formUpdate}
                          data-testid="first-name-input"
                        ></input>
                      </label>
                      <label>
                        <p>Last name</p>
                        <input
                          placeholder="Smith"
                          name="lastname"
                          value={formState.lastname}
                          onChange={formUpdate}
                          data-testid="last-name-input"
                        ></input>
                      </label>
                    </div>
                    <label>
                      <p>Business name</p>
                      <input
                        placeholder="[Example] un:hurd music"
                        name="businessName"
                        value={formState.businessName}
                        onChange={formUpdate}
                        data-testid="business-name-input"
                      ></input>
                    </label>
                    <label>
                      <p className="mt16">Bio</p>
                      <div className="pos-rel">
                        <textarea
                          placeholder="Tell us a bit about yourself - what inspired you to create your playlists, what you love about music, etc."
                          name="bio"
                          maxLength={500}
                          value={formState.bio}
                          onChange={formUpdate}
                          data-testid="bio-input"
                        ></textarea>
                        <div className="textarea-counter">
                          (minimum 100 characters) {formState.bio?.length || 0}/500
                        </div>
                      </div>
                    </label>
                    <label>
                      <p className="mt16">Vibe</p>
                      <div className="pos-rel">
                        <textarea
                          placeholder="[Example] Fresh cuts from indie, electronic, and soulful R&B"
                          name="vibe"
                          maxLength={100}
                          value={formState.vibe}
                          onChange={formUpdate}
                          className="vibe-textarea"
                          data-testid="vibe-input"
                        ></textarea>
                        <div className="textarea-counter">{formState.vibe?.length || 0}/100</div>
                      </div>
                    </label>
                    <label>
                      <p className="mt16">Email address</p>
                      <input
                        placeholder="Enter email address"
                        name="email"
                        disabled={true}
                        value={formState.email}
                        onChange={formUpdate}
                        data-testid="email-input"
                      ></input>
                    </label>
                  </form>
                )}
                <HolidayMode isAccountMode={true} />
              </div>
              <Button
                disabled={isSavingChanges}
                className="btn-white btn-no-margins mt16 ml0"
                onClick={handleFormChanges}
                data-testid="save-changes-button"
              >
                {isSavingChanges ? <CircularProgress size={16} /> : 'Save changes'}
              </Button>
            </div>
            {/* Payment details tab */}
            <div hidden={value !== 1}>
              {unhurdUser && !unhurdUser?.paymentDetails?.payPal?.email ? (
                <div className="text-center">
                  <img className="mt48" src="/images/paypal-logo.svg" alt="email"></img>
                  <div></div>
                  <p className="text-faded max-w600 m-auto mt32">
                    You haven’t connected a PayPal account yet. In order to get paid from your playlist pitches you need
                    to connect and verify your PayPal account.
                  </p>
                  <div className="mt32">
                    <PaypalLogin page="settings" />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="top-cards">
                    <div className="title-card max-w500">
                      <div className="card-title">
                        <img className="card-icon" src="/images/paypal-logo.svg" alt="email"></img>
                        <div className="titles">
                          <div className="text-faded">PayPal account</div>
                          <div className="text-number">Connected</div>
                        </div>
                        <div className="ml-auto">
                          <PaypalLogin page="settings" type="change" />
                        </div>
                      </div>
                      <label>
                        <p className="mt16">Email address</p>
                        <input
                          placeholder="Enter email address"
                          name="email"
                          disabled={true}
                          value={unhurdUser?.paymentDetails?.payPal.email || ''}
                          data-testid="paypal-email-input"
                        ></input>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Security settings tab */}
            <div hidden={value !== 2}>
              <h3>Security settings</h3>
              <Button
                disabled={isChangingPassword}
                onClick={handlePasswordChanges}
                data-testid="change-password-button"
                className="ml0"
              >
                {isChangingPassword ? <CircularProgress size={16} /> : 'Change password'}
              </Button>
              <Button onClick={deleteAccount} data-testid="delete-account-button">
                Delete Account
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <h4>Are you sure you want to delete your account?</h4>
                <div className="d-flex jc-center mt16">
                  <Button
                    className="border-btn"
                    onClick={() => {
                      handleClose(false);
                    }}
                    data-testid="deny-delete-account"
                  >
                    No
                  </Button>{' '}
                  <Button
                    className="btn-white"
                    onClick={() => {
                      handleClose(true);
                    }}
                    data-testid="confirm-delete-account"
                  >
                    Yes
                  </Button>
                </div>
              </Dialog>
            </div>
            {/* Development settings tab */}
            <div hidden={value !== 3}>
              <h3>Development settings</h3>
              <Button disabled={isCreatingPitches} onClick={createDemoPitches} className="ml0">
                {!isCreatingPitches ? 'Create demo pitches' : <CircularProgress size={16} />}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </>
  );
}
